import { Authentication } from '../store';

const request = async (url, data) => {
  if (!url.includes('login') && !Authentication.isAuthenticated()) {
    window.location.href = '/login';
  }
  const method = data ? 'POST' : 'GET';
  const body = data ? JSON.stringify(data) : null;

  const result = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${Authentication.token}`,
    },
    body,
  });

  const response = await result.json();
  if (response.errcode === 40001) {
    window.location.href = '/login';
  }

  return response;
};

export const login = async (userInfo) => {
  try {
    const response = await request('/ewlAdmin/login', userInfo);
    return response.errcode === 200 ? response.accessToken : null;
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const companyList = async () => {
  try {
    const response = await request('/ewlAdmin/companyList', { args: 'args' });
    return response.errcode === 200 ? response.companyList : [];
  } catch (e) {
    return [];
  }
};

export const companySave = async (company) => {
  const response = await request('/ewlAdmin/companySave', company);
  return response.errcode === 200 ? response.id : 0;
};

export const genQRCode = async (args) => {
  const response = await request('/ewlAdmin/genQRCode', args);
  return response.errcode === 200 ? response.qrCode : '';
};

export const deviceRecordStatistic = async (startDate, endDate) => {
  try {
    const response = await request('/ewlAdmin/deviceRecord', {
      startDate,
      endDate,
    });
    return response.errcode === 200 ? response : {};
  } catch (e) {
    return [];
  }
};
