import React from 'react';
import { PieChartOutlined, ContainerOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Company from './pages/Company';
import DeviceRecordStatistic from './pages/DeviceRecordStatistic';

const Routes = [
  {
    key: 'home',
    path: '/',
    exact: true,
    main: () => <Redirect to="/dashboard" />,
  },
  {
    path: '/dashboard',
    key: 'dashboard',
    sidebar: () => (
      <>
        <PieChartOutlined />
        <span>控制台</span>
      </>
    ),
    main: () => <Dashboard />,
  },
  {
    path: '/company',
    key: 'company',
    sidebar: () => (
      <>
        <ContainerOutlined />
        <span>客户</span>
      </>
    ),
    main: () => <Company />,
  },
  {
    path: '/deviceStatistic',
    key: 'deviceStatistic',
    sidebar: () => (
      <>
        <PieChartOutlined />
        <span>设备统计</span>
      </>
    ),
    main: () => <DeviceRecordStatistic />,
  },
];

export default Routes;
