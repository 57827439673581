import React, { useState } from 'react';
import { Button, Table, DatePicker } from 'antd';
import { deviceRecordStatistic } from '../api';

import './DeviceRecordStatistic.css';

const { RangePicker } = DatePicker;

const DeviceRecordStatistic = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sta, setSta] = useState([]);
  const [records, setRecords] = useState([]);
  const columns = [
    {
      title: '生产',
      dataIndex: 'create',
      key: 'create',
    },
    {
      title: '自检',
      dataIndex: 'selfTest',
      key: 'selfTest',
    },
    {
      title: '出货',
      dataIndex: 'merchantAt',
      key: 'merchantAt',
    },
    {
      title: '机台绑定',
      dataIndex: 'setParams',
      key: 'setParams',
    },
    {
      title: '客户绑定',
      dataIndex: 'companyBind',
      key: 'companyBind',
    },
    {
      title: '正常工作',
      dataIndex: 'firstWork',
      key: 'firstWork',
    },
  ];

  const columnRecord = [
    {
      title: '设备编号',
      dataIndex: 'deviceCode',
      key: 'deviceCode',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        if (status === 0) {
          return <span>生产</span>;
        }
        if (status === 1) {
          return <span>自检</span>;
        }
        if (status === 2) {
          return <span>出货</span>;
        }
        if (status === 3) {
          return <span>机台绑定</span>;
        }
        if (status === 4) {
          return <span>客户绑定</span>;
        }
        if (status === 5) {
          return <span>正常工作</span>;
        }
        return <span>未知</span>;
      },
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: '机台编号',
      dataIndex: 'params',
      key: 'params',
      render: (params) => {
        if (params.product_no) {
          return <span>{params.product_no}</span>;
        }
        return <span />;
      },
    },
    {
      title: '客户名称',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: '时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
    },
  ];
  const queryStatistic = async () => {
    const s = await deviceRecordStatistic(startDate, endDate);
    setSta([s.sta]);
    setRecords(s.records);
  };

  const rangePickerChange = (dates, datesString) => {
    setStartDate(datesString[0]);
    setEndDate(datesString[1]);
  };

  return (
    <div className="device_record_statistic">
      <div className="device_record_statistic_tool">
        <RangePicker onChange={rangePickerChange} />
        <Button type="primary" onClick={() => queryStatistic()}>
          查询
        </Button>
      </div>

      <Table columns={columns} dataSource={sta} pagination={false} />

      <Table columns={columnRecord} dataSource={records} />
    </div>
  );
};

export default DeviceRecordStatistic;
