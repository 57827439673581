const init = () => {
  const token = localStorage.getItem('token');
  const timestamp = localStorage.getItem('timestamp');
  if (timestamp) {
    Store.auth.token = token;
    Store.auth.timestamp = parseInt(timestamp);
  }
};

const tokenSave = (token) => {
  const timestamp = String(Math.round(new Date().getTime() / 1000));
  localStorage.setItem('token', token);
  localStorage.setItem('timestamp', timestamp);
  Store.auth.token = token;
  Store.auth.timestamp = timestamp;
};

const tokenDestroy = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('timestamp');
  Store.auth.token = '';
  Store.auth.timestamp = 0;
};

const Store = {
  auth: {
    token: '',
    timestamp: 0,
  },
  init,
  tokenSave,
  tokenDestroy,
};

export const Authentication = {
  token: '',
  timestamp: 0,
  login(token) {
    Authentication.token = token;
    Authentication.timestamp = Math.round(new Date().getTime() / 1000);
    localStorage.setItem(
      'auth',
      JSON.stringify({
        token: Authentication.token,
        timestamp: Authentication.timestamp,
      }),
    );
  },
  logout() {
    Authentication.token = '';
    Authentication.timestamp = 0;
    localStorage.removeItem('auth');
  },
  init() {
    const authStr = localStorage.getItem('auth');
    if (authStr) {
      const auth = JSON.parse(authStr);
      Authentication.token = auth.token;
      Authentication.timestamp = auth.timestamp;
    }
  },
  isAuthenticated() {
    const now = Math.round(new Date().getTime() / 1000);
    return now - Authentication.timestamp < 7000;
  },
};

export default Store;
