import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Authentication } from '../store';

const AuthRoute = ({ children, ...rest }) => {
  const authenticated = Authentication.isAuthenticated();
  return (
    <Route
      {...rest}
      render={() => (authenticated ? children : <Redirect to="/login" />)}
    />
  );
};

export default AuthRoute;
