import React, { useState } from 'react';
import './Home.css';
import { useHistory, Switch, Route } from 'react-router-dom';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { Authentication } from '../store';
import Sidebar from '../components/Sidebar';
import Routes from '../Router';

const Home = () => {
  const history = useHistory();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Footer, Sider, Content } = Layout;

  const logout = () => {
    Authentication.logout();
    history.replace('/login');
  };

  const toggleMenu = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  return (
    <Layout style={{ minHeight: '100%' }}>
      <Sider style={{ width: 256 }} collapsed={menuCollapsed}>
        <Sidebar />
      </Sider>
      <Layout>
        <Header style={{ padding: 0 }}>
          <div className="global-header">
            <span className="menu-trigger" onClick={toggleMenu}>
              {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </span>
            <span className="logout" onClick={logout}>
              登出
            </span>
          </div>
        </Header>
        <Content>
          <Switch>
            {Routes.map((route) => (
              <Route key={route.key} path={route.path} exact={route.exact}>
                {route.main}
              </Route>
            ))}
          </Switch>
        </Content>
        <Footer>育成信息科技</Footer>
      </Layout>
    </Layout>
  );
};

export default Home;
