import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import Routes from '../Router';

const Sidebar = () => {
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  return (
    <Menu
      style={{ height: '100%' }}
      defaultSelectedKeys={[paths[paths.length - 1]]}
      mode="inline"
      theme="dark"
    >
      {Routes.map((route) => {
        if (!route.sidebar) {
          return '';
        }
        return (
          <Menu.Item key={route.key}>
            <Link to={route.path}>
              <route.sidebar />
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default Sidebar;
