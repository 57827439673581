import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Popconfirm,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from 'antd';
import './Company.css';
import QrCode from 'qrcode.react';
import { companyList as getCompanyList, companySave, genQRCode } from '../api';

const Company = () => {
  const { Option } = Select;
  const [companyList, setCompanyList] = useState([]);
  const [editingId, setEditingId] = useState(-1);
  const [form] = Form.useForm();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [qrForm] = Form.useForm();
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [qrCodeCompany, setQrCodeCompany] = useState({});

  useEffect(() => {
    async function loadCompanyList() {
      const cList = await getCompanyList();
      setCompanyList(cList);
    }
    loadCompanyList();
  }, []);

  const onQrCodeClick = ({ id, name }) => {
    setQrCodeCompany({ companyID: id, name });
    setDialogVisible(true);
    setQrCodeValue('');
  };

  const cancelEdit = () => {
    setEditingId(-1);
  };

  const saveEdit = async () => {
    const row = form.getFieldsValue();
    const newData = [...companyList];
    const index = newData.findIndex((item) => editingId === item.id);
    if (index > -1) {
      const item = newData[index];
      const newItems = { ...item, ...row };
      const id = await companySave(newItems);
      if (id !== 0) {
        newItems.id = id;
        newData.splice(index, 1, newItems);
        setCompanyList(newData);
        setEditingId(-1);
      }
    }
  };

  const editCompany = (record) => {
    setEditingId(record.id);
    form.setFieldsValue({ ...record });
  };

  const newCompany = () => {
    const record = { id: 0 };
    const newData = [record, ...companyList];
    setCompanyList(newData);
    editCompany(record);
  };

  const deleteCompany = (id) => {};

  const columns = [
    {
      title: '公司名称',
      dataIndex: 'name',
      key: 'name',
      editable: true,
    },
    {
      title: '编码',
      dataIndex: 'code',
      key: 'code',
      editable: true,
    },
    {
      title: '时区/小时',
      dataIndex: 'timezoneHour',
      key: 'timezoneHour',
      editable: true,
    },
    {
      title: '时区/分钟',
      dataIndex: 'timezoneMinute',
      key: 'timezoneMinute',
      editable: true,
    },
    {
      title: '地址',
      dataIndex: 'addrRemark',
      key: 'addrRemark',
      editable: true,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      editable: true,
    },
    {
      title: '操作',
      dataIndex: '',
      key: 'action',
      render: (text, record) => {
        const editing = record.id === editingId;
        return editing ? (
          <span>
            <Button type="link" onClick={() => saveEdit()}>
              保存
            </Button>
            <Popconfirm title="确定取消编辑?" onConfirm={cancelEdit}>
              <Button type="link">取消</Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button type="link" onClick={() => onQrCodeClick(record)}>
              二维码
            </Button>
            <Button type="link" onClick={() => editCompany(record)}>
              编辑
            </Button>
            <Button type="link" onClick={() => deleteCompany(record.id)}>
              删除
            </Button>
          </span>
        );
      },
    },
  ];
  const inputType = (dataIndex) => {
    if (dataIndex === 'timezoneHour' || dataIndex === 'timezoneMinute') {
      return 'number';
    }
    return 'text';
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: inputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.id === editingId,
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const makeQrCode = async () => {
    setConfirmLoading(true);
    const qr = qrForm.getFieldsValue();
    qr.companyID = qrCodeCompany.companyID;
    console.log(qr, qrCodeCompany);
    const qrcode = await genQRCode(qr);
    setConfirmLoading(false);
    setQrCodeValue(qrcode);
  };
  const onQrAuthChange = (value) => {
    qrForm.setFieldsValue({ qrCodeType: value });
  };
  const onLangChange = (value) => {
    qrForm.setFieldsValue({ language: value });
  };

  return (
    <div className="company">
      <Button type="primary" onClick={() => newCompany()}>
        新建
      </Button>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          style={{ marginTop: 20 }}
          dataSource={companyList}
          columns={mergedColumns}
          rowKey="id"
        />
      </Form>

      <Modal
        title="生成二维码"
        visible={dialogVisible}
        onOk={() => makeQrCode()}
        confirmLoading={confirmLoading}
        onCancel={() => setDialogVisible(false)}
        forceRender
      >
        <Form
          form={qrForm}
          component={false}
          initialValues={{ qrCodeType: 1, language: 'zh_CN' }}
        >
          <h3 style={{ textAlign: 'center' }}>{qrCodeCompany.name}</h3>
          <Form.Item label="权限" name="qrCodeType">
            <Select onChange={onQrAuthChange}>
              <Option value={1}>系统管理</Option>
              <Option value={2}>设备管理</Option>
              <Option value={3}>设备监控</Option>
            </Select>
          </Form.Item>
          <Form.Item label="语言" name="language">
            <Select onChange={onLangChange}>
              <Option value="zh_CN">简体中文</Option>
              <Option value="zh_TW">繁体中文</Option>
              <Option value="en">English</Option>
            </Select>
          </Form.Item>
        </Form>
        <p style={{ textAlign: 'center' }}>
          {qrCodeValue !== '' ? <QrCode size={256} value={qrCodeValue} /> : ''}
        </p>
      </Modal>
    </div>
  );
};

export default Company;
