import React from "react";
import { useHistory } from "react-router-dom";
import { login } from "../api";
import { Authentication } from "../store";
import { Button, Layout, Form, Input } from "antd";
import "./Login.css";

const Login = () => {
  let history = useHistory();
  const { Footer, Content } = Layout;

  const [form] = Form.useForm();

  const loginClick = async () => {
    const info = form.getFieldsValue();
    const token = await login(info);
    if (token) {
      Authentication.login(token);
      history.push("/");
    } else {
      console.log("login fail");
    }
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  return (
    <Layout style={{ minHeight: "100%" }}>
      <Content>
        <div className="form-wrap">
          <Form {...layout} form={form}>
            <h1>登录</h1>
            <Form.Item label="用户名" name="username">
              <Input />
            </Form.Item>
            <Form.Item label="密码" name="password">
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" onClick={loginClick}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
      <Footer></Footer>
    </Layout>
  );
};

export default Login;
